/* CheckoutPage.css */
.payment-page {
    display: flex;
    justify-content: center; /* Horizontally center */
    min-height: 100vh; /* Ensure full viewport height */
    background-color: #F5F5F1;
    font-family: sans-serif;
  }
  
  .checkout-container {
    background-color: #F5F5F1;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .payment-options {
    display: flex;
    margin-bottom: 20px;
  }
  
  .option-button {
    padding: 10px 20px;
    border: none;
    margin-right: 10px; /* Add spacing between buttons */
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .option-button:hover {
    background-color: #e0e0e0;
  }
  
  .option-button.active {
    background-color: #007bff; /* Bootstrap blue */
    color: white;
  }
  

  nav {
    display: flex;
    justify-content: space-between; /* Space out child elements */
    align-items: center; /* Vertically center items */
    padding: 5px 10px 15px 10px;
    border-bottom: 1px solid var(--light-accent);
    color: var(--dark-shades);
    font-size: 16px; /* Adjust font size */
    line-height: 1; /* Adjust line height */
    position: relative; /* Create positioning context for absolute positioning */
  }
  
  nav .centered-links {
    display: flex;
    justify-content: center; /* Center these links within their space */
    position: absolute; /* Absolute positioning to center independently */
    left: 50%; /* Center horizontally relative to the parent */
    transform: translateX(-50%); /* Offset by half of its own width */
    gap: 30px; /* Space between centered links */
    margin-left: -5px; /* Slight offset to the left */
  }
  
  nav .right-links {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-left: auto; /* Push the right links to the far right */
  }
  
  nav .right-links li {
    margin-left: 15px; /* Space between right-aligned items */
  }
  
  nav ul {
    display: flex;
    list-style-type: none; /* Remove bullet points */
    padding: 0;
    margin: 0;
  }
  
  nav a {
    text-decoration: none; /* Remove underline from links */
    color: var(--dark-shades); /* Link color */
    font-weight: bold; /* Make text bold */
  }

  .dropdown {
    position: relative; /* Necessary for absolute positioning of the dropdown content */
  }
  
  .dropdown select {
    /* Style the select element itself */
    background-color: #E84C4E; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    appearance: none; /* Remove default styling */
    -webkit-appearance: none; /* Remove default styling for Safari */
  }
  
  .dropdown option {
    /* Style the options within the dropdown */
    background-color: #f9f9f9;
    color: black;
    padding: 12px 16px;
  }
  
  .dropdown option:hover {
    background-color: #f1f1f1;
  }