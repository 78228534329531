/* General Styles */
:root {
  --main: #E84C4E;
  --light-accent: #B88786;
  --light-shades: #F5F5F1;
  --dark-accent: #E66B5F;
  --dark-shades: #31625C;
  --button-height: "30px";
}
/* :root {
  --main: #F7F7F6;
  --light-accent: #BE8468;
  --light-shades: #B99F9A;
  --dark-accent: #7E3F54;
  --dark-shades: #373335;
} */

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  line-height: 1.6;
  color: var(--dark-shades);;
  /* background: linear-gradient(135deg, #f0f5fa, #f9e2bf, #dcc0f8); */
  background: var(--light-shades);
  overflow-y: scroll; /* Enable vertical scrolling */
}

section { 
  font-family: 'Open Sans', sans-serif;
  scroll-snap-align: start;
  min-height: 100vh; /* Ensure sections fill the viewport height */
  display: flex; /* Enable flexbox for centering content */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

h1 {
  text-align: center;
}
h2 {
  text-align: center;
}

section h2 {
  text-align: center;
}

/* Set up basic styling and font */
.flashy-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5em;
  color: var(--dark-shades);
  /* text-shadow: 0 0 10px var(--dark-shades); */
  clip-path: inset(0 0 0 100%); /* Initially clip everything except the top */
  animation: reveal 1s ease-in-out forwards;
  margin-bottom: 0px;
}

/* Define the animation keyframes */
@keyframes reveal {
  0% {
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0); /* Initially, a thin vertical line on the left edge */
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Reveal the entire rectangle */
  }
}

/* Create the animation */
.flashy-title::before { /* Use ::before instead of ::after */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0; /* Initial width is 0 */
  height: 100%;
  background-color: #fff; 
  animation: reveal 2s ease-in-out forwards;
  z-index: -1; /* Place it behind the text */
}


form {
  max-width: 400px; /* Adjust width as needed */
  margin: 80px auto;  /* Center the form */
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  background-color: var(--light-shades);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.landing-container {
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: 100vh; /* Make the container take full viewport height */
}

/* nav ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav li {
  margin: 0 2px;
  border: none;
  padding: 8px 0;
} */

/* nav li:nth-last-child(2) { 
  margin-left: auto;
}

nav li:last-child {
  margin-right: 0;   
} */
nav {
  display: flex;
  justify-content: space-between; /* Space out child elements */
  align-items: center; /* Vertically center items */
  padding: 5px 10px 15px 10px;
  border-bottom: 1px solid var(--light-accent);
  color: var(--dark-shades);
  font-size: 16px; /* Adjust font size */
  line-height: 1; /* Adjust line height */
  position: relative; /* Create positioning context for absolute positioning */
}

nav .centered-links {
  display: flex;
  justify-content: center; /* Center these links within their space */
  position: absolute; /* Absolute positioning to center independently */
  left: 50%; /* Center horizontally relative to the parent */
  transform: translateX(-50%); /* Offset by half of its own width */
  gap: 30px; /* Space between centered links */
  margin-left: -5px; /* Slight offset to the left */
}

nav .right-links {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-left: auto; /* Push the right links to the far right */
}

nav .right-links li {
  margin-left: 15px; /* Space between right-aligned items */
}

nav ul {
  display: flex;
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

nav a {
  text-decoration: none; /* Remove underline from links */
  color: var(--dark-shades); /* Link color */
  font-weight: bold; /* Make text bold */
}

/* Adjust header padding to control top and bottom margins */
header {
  /* background: linear-gradient(to right, #ff9900, #9933ff); */
  /* background: linear-gradient(135deg, #f0f5fa, #f9e2bf, #dcc0f8); */
  background: var(--light-shades);
  backdrop-filter: blur(10px); /* Add a subtle blur effect for better readability */
  /* color: white; */
  
  padding: 15px 0; /* Adjust these values as needed */
  /* border-radius: 8px; */
  overflow: hidden;
}


nav a:hover {
  color: var(--dark-shades); /* Lighten on hover */
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
}

/* Hero Section */
.hero {
  text-align: center;
  padding: 0px 0;
  background: var(--light-accent);; /* Match body background for seamless transition */
}

.hero-content {
  background-color: var(--light-shades);;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow */
}

.hero h1 {
  color: var(--dark-shades); /* Orange heading */
}

.delete-button {
  background-color: var(--main); /* Purple button */
  color: var(--light-shades);;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button {
  background-color: var(--main); /* Purple button */
  color: var(--light-shades);;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--dark-accent); /* Darker purple on hover */
}

.cta-input {
  background-color: var(--light-shades);  /* Inverted colors */
  color: var(--main);                      /* Inverted colors */
  padding: 15px 30px;                      /* Same padding */
  border: none;                            /* Same border */
  border-radius: 5px;                       /* Same border-radius */
  font-size: 1rem;                         /* Same font size */
  box-sizing: border-box;                  /* Important: Include padding in width calculation */
  width: 100%;                             /* Adjust width as needed */
  transition: background-color 0.3s ease;  /* Same transition effect */
  box-shadow: 0 0 1px var(--dark-shades); /* Add a subtle purple shadow */
}

.cta-input:focus { /* Style for when the input is focused */
  outline: none;       /* Remove default outline */
  box-shadow: 0 0 5px var(--main); /* Add a subtle purple shadow */
}


/* How It Works Section */
.how-it-works {
  text-align: center;
  padding: 80px 0;
}

.how-it-works h2 {
  color: var(--dark-shades);
}


.carousel-container {
  margin-top: "-590px";
  overflow-x: auto;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
  display: flex; /* Use flexbox for the container */
}

.carousel-container::-webkit-scrollbar { 
  display: none;  /* Chrome, Safari and Opera */
}

.prev-button { 
  /* Basic button styles */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left:100px;
}

.prev-button:disabled {
  opacity: 0.5; 
  cursor: not-allowed;
}

.prev-button::before { 
  /* Left arrow for prev button */
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 30px 15px 0;
  border-color: transparent var(--dark-shades) transparent transparent;
  margin-right: 5px;
}

.next-button { 
  /* Basic button styles */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right:100px;
}

.next-button:disabled {
  opacity: 0.5; 
  cursor: not-allowed;
}

.next-button::before {
  /* Right arrow for next button */
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 30px; 
  border-color: transparent transparent transparent var(--dark-shades); 
  margin-left: 5px;
}


.carousel-wrapper {
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Vertically center the items */
}


.carousel-section {
  flex: 0 0 auto; 
  min-width: 100%; /* Ensure each section takes at least full viewport width */
  word-break: break-word;
  vertical-align: top; 
  text-align: center;
}



.pricing-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px;
  padding: 50px; /* Add padding to the table */
}

.plan-header {
  /* Style for the plan header (name, price, button) */
  text-align: center;
  padding: 20px;
  border-radius: 8px 8px 0 0; /* Rounded top corners */
  background-color: var(--light-shades);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: span 1; /* Each plan header spans one column */
  display: flex; /* Use flexbox for vertical spacing */
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  gap: 10px; /* Add spacing between header elements */
}

.plan-header:nth-child(1) {
  grid-column: 2 / 3; /* First plan header starts at column 2, ends at 3 */
}

.plan-header:nth-child(2) {
  grid-column: 3 / 4; /* Second plan header starts at column 3, ends at 4 */
}

.plan-header:nth-child(3) {
  grid-column: 4 / 5; /* Third plan header starts at column 4, ends at 5 */
}

.plan-header h3,
.plan-header p {
  margin: 5px 0;
}

.feature-row {
  display: contents;
}

.feature-name {
  grid-column: 1;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid lightgray; /* Add underline */
}

.plan-value {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid lightgray; 
  background-color: var(--light-shades); /* Background for plan values */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.plan-value:first-of-type {
  border-radius: 0 0 0 8px; /* Rounded bottom-left corner for the first plan */
}

.plan-value:last-of-type {
  border-radius: 0 0 8px 0; /* Rounded bottom-right corner for the last plan */
}

.plan .cta-button {
  margin-top: 20px; /* Add spacing above the button */
}

.plan {
  /* Existing styles for .step can be mostly reused */
  padding: 20px;
  text-align: center;
  background-color: var(--light-shades);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Style plan header */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan h3,
.plan p {
  margin: 5px 0;
}

.plan .features-list {
  list-style: none;
  padding: 0;
  text-align: left; /* Align feature values to the left */
  margin-top: 20px;
}

.plan .features-list li {
  margin-bottom: 10px;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; /* Wrap on smaller screens */
}

.step {
  margin: 0 10px 20px; /* Add margin for spacing */
  margin-bottom: 30px;
  padding: 30px;
  text-align: center;
  width: 150px;
  height: 400px;
  background-color: var(--light-shades);;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.step i { /* Icon styles (assuming you're using Font Awesome or similar) */
  font-size: 3rem;
  color: var(--light-accent);;
  margin-bottom: 20px;
}

/* Pricing and other sections... */
/* ... your existing styles ... */

/* ... your existing styles ... */

/* Responsive Design */
/* @media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  /* .steps {
    flex-direction: column;
  }
  
  .step {
    width: 90%;
  } 
} */

.getting-started {
  text-align: center;
  padding: 80px 0; 
  background-color: var(--light-shades);; /* White background for the section */
  border-radius: 8px;       /* Rounded corners */
}

.getting-started h2 {
  color: var(--dark-accent);; /* Purple heading */
}

/* Style the steps container to match the "How It Works" section */
.getting-started .steps { /* Use the existing .steps styles */
  /* ... (you can customize this if needed) ... */
}

/* Style the notes list */
.notes-list {
  list-style: none;
  padding: 0;
  text-align: center; /* Align text to the left */
}

.notes-list li {
  margin-bottom: 10px;
}

/* Pricing Section */
.pricing {
  text-align: center;
  background-color: #f0f5fa; /* Same background as hero for smooth transition */
}
/* 
.pricing h2 {
} */

/* Style the steps for the pricing options */

/* Feature List */
.features-list {
  list-style: none;
  padding: 0;
  text-align: center;
}

.features-list li {
  margin-bottom: 10px;
}

/* Features Section */
.features {
  text-align: center;
  padding: 80px 0;
  background-color: white; /* White background for the section */
  border-radius: 8px;       /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow */
}

.features h2 {
  color: var(--dark-shades); /* Purple heading */
  margin-bottom: 40px;
}


/* Basic Table Styling */
.account-table {
  /* width: 100%; */
  border-collapse: collapse; /* Remove extra spacing between borders */
  margin-bottom: 20px;
}

/* Table Header Styling */
.account-table th,
.account-table td {
  border: 1px solid #ddd; /* Light gray border */
  padding: 10px;         /* Padding around cell content */
  text-align: left;
}

/* First Column Styling */
.account-table th:first-child,
.account-table td:first-child {
  width: 150px; /* Adjust width as needed */
}

/* Second Column Styling */
.account-table th:nth-child(2),
.account-table td:nth-child(2) {
  width: 300px; /* Adjust width as needed */
}

/* Table Header Styling */
.account-table th {
  background-color: #f2f2f2; /* Light background for header cells */
  font-weight: bold;
}

/* Alternating Row Colors (Optional) */
.account-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Slightly lighter background for even rows */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .account-table {
    font-size: 0.9em; /* Reduce font size on smaller screens */
  }
  
  .account-table th,
  .account-table td {
    padding: 8px; /* Reduce padding on smaller screens */
  }

  /* Adjust column widths for smaller screens if needed */
  .account-table th:first-child,
  .account-table td:first-child {
    width: 100px; /* Adjust width for smaller screens */
  }
  
  .account-table th:nth-child(2),
  .account-table td:nth-child(2) {
    width: 200px; /* Adjust width for smaller screens */
  }
}

.feature-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 10px; /* Space between the widgets */
}

.widget:nth-child(odd) {
  grid-column: 1; /* Odd widgets go in the first column */
}

.widget:nth-child(even) {
  grid-column: 2; /* Even widgets go in the second column */
}



.text-image-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* Default: text on the left, image on the right */
}

.text-content {
  width: 400px;
  margin-right: 50px; /* Add some spacing between text and image */
}

.image-content {
  margin-left: 0; /* Remove margin from left side */
}

.image-content img {
  max-width: 100%; /* Ensure image fits within its container */
  height: auto;
}

/* Alternate layout: image on the left, text on the right */
.text-image-container.alternate .text-content {
  order: 2; /* Change the order of text content */
  margin-left: 20px; /* Consistent spacing for alternate layout */
  margin-right: 0; /* Remove margin-right in alternate layout */
}

.text-image-container.alternate .image-content {
  order: 1; /* Change the order of image content */
  margin-right: 20px; /* Consistent spacing for alternate layout */
}



.pre-order-page {
  /* font-family: sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px; */
  text-align: center;
}

.product-description, .shipping-date {
  margin-bottom: 10px;
}

.disclaimer {
margin-top: 10px;
font-size: 0.9em;
color: #666;
}

.pre-order-form div { /* Targets the divs wrapping the inputs */
  margin-bottom: 15px;
  border: none; /* Remove any border */
}
.pre-order-form{
  border: none;
  margin-top: "-50px";
}

/* New cta-input2 class */
.cta-input2 {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: none; /* Remove all borders */
  border-bottom: 1px solid var(--light-accent); /* Add bottom border */
  border-radius: 0;  /* Remove border-radius */
  margin: 0 auto;
  display: block;
  background-color: transparent; /* Remove any background color */
  outline: none; /* Remove the outline on focus (handle accessibility!) */
}

/*  Optional: Style the input on focus */
.cta-input2:focus {
  border-bottom-color: var(--main); /* Change underline color on focus */
}

.cta-button2 {
  width: 100%;
  max-width: 300px;
  padding: 10px 15px;
  border: 1px solid var(--main);
  border-radius: 4px;
  background-color: var(--main);
  color: white;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  height: var(--button-height);
}

.cta-button2:disabled {
  background-color: var(--light-shades);
  color: var(--dark-shades);
  cursor: not-allowed;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.error-message {
  color: red;
}

.success-message {
  color: green;
  text-align: center;
}

.options-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.option-button {
  padding: 10px 15px;
  color: var(--dark-shades);
  background-color: var(--light-shades);
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  height: var(--button-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-button.selected {
  background-color: var(--main);
  color: white;
  border-color: var(--main);
}
.product-price{
  margin-bottom: 1rem;
}