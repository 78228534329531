.article-container {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center;
  width: 900px; /* Set the fixed width */
  max-width: calc(100% - 100px); /* Prevent container from being too wide */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px;
  hyphens: auto; /* Enable hyphenation */
}

figcaption {
  font-style: italic;
  font-size: 0.8em;
  color: #31625C;
  margin-top: 5px; /* Add some space between the image and caption */
  text-align: center;
}

/* Optional: Media query for very small screens */
@media (max-width: 900px) { /* Adjust breakpoint as needed */
  .article-container {
    width: 100%; /* Take up full width on very small screens */
    margin: 0 auto; /* Center the container horizontally */
    box-sizing: border-box; /* Include padding and border in the width */
    word-wrap: break-word; /* Wrap long words */
    overflow-wrap: break-word; /* For older browsers */
  }
}

.pricing-table-blog {
  display: table;
  width: 100%;
  max-width: 900px;
  margin: 0px auto;
  border-collapse: separate; /* Important for spacing */
  border-spacing: 0;     /* Control spacing between cells */
  table-layout: fixed;
  border: none;

}

.pricing-row {
  display: table-row;
}

.feature-cell {
  display: table-cell;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  width: 30%;
  /* Remove direct borders, handle with padding/spacing */
  padding-right: 5px; /* Add a small gap */

}
/* Remove top border from the first feature cell*/
.pricing-row:first-child .feature-cell{
  border-top: none;
}
/* Remove bottom border from the last feature cell*/
.pricing-row:nth-last-child(2) .feature-cell{
 border-bottom: none;
}
.product-cell {
  display: table-cell;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  width: 23.33%;
  /* Remove direct borders, handle with padding/spacing */
  padding-left: 5px;  /* Add a small gap */
  padding-right: 5px; /* Add a small gap */
}
.product-cell:first-child{
  border-left: none; /* Remove the very first vertical border*/
}

.product-header-cell {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
  border-bottom: 1px solid #ccc; /* Add border to header cells*/
  /* padding-top: 20px; */
  padding-bottom: 10px;
  padding-right: 5px;

}
.product-header-cell:first-child{
   padding-left: 5px;
}

.product-image-cell {
  padding: 10px;
  border-bottom: none;
  padding-right: 5px;
   padding-left: 5px;

}
.product-image-cell:first-child{
   padding-left: 10px;
}
.product-image {
  max-width: 100%;
  height: auto;
}

.price-cell {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  border-top: 1px solid #ccc;
  padding-right: 5px;
   padding-left: 5px;
}
.price-cell:first-child{
   padding-left: 10px;
}
.product-cell:last-child {
  border-right: 1px solid #ccc;
}
.price-top{
  border-bottom: none;
}

.yes {
  color: green;
}

.no {
  color: red;
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-table {
      display: block;
      border-spacing: 0; /* Reset border spacing for block layout*/

  }

  .pricing-row,
  .feature-cell,
  .product-cell,
  .product-header-cell,
  .product-image-cell,
  .price-cell
   {
      display: block;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      padding: 10px; /* Reset padding*/
      border: none; /*Reset all borders*/
  }
  .pricing-row > *:first-child{
       border-top: 1px solid #ccc; /*Add a single top border*/
  }
  .pricing-row > *:last-child{
       border-bottom: 1px solid #ccc; /*Add a single bottom border*/
  }
  .product-image-cell{
      border-bottom: 1px solid #ccc;
  }
  .product-image{
      width: 50%;
  }
}